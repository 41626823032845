<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal" :widthModal="1050">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Dados Usuário Web - {{ item.jsonData.fullName }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <div class="abas" style="width: 95%;">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px">
            <v-tab href="#tab-1" class="abas-item">
              Dados pessoais
            </v-tab>
            <!-- <v-tab 
              href="#tab-2" 
              class="abas-item"
              @click="openPremioModal">
              Prêmios
            </v-tab> -->
            <!-- <v-tab 
              href="#tab-3" 
              class="abas-item">
              Dados bancário
            </v-tab> -->
            <v-tab 
              href="#tab-4" 
              class="abas-item"
              @click="openExtratoCreditoModal">
              Extrato Creditos
            </v-tab>
            <!-- <v-tab 
              href="#tab-5" 
              class="abas-item"
              @click="openTitulosCapitalizacao">
              Titulos Capitalização
            </v-tab> -->
             <v-tab 
              href="#tab-6" 
              class="abas-item"
              @click="openExtratoPremioModal">
              Extrato Prêmios
            </v-tab>
          </v-tabs>
        </div>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :value="'tab-1'">
          <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loaderModal">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>

          <dadosPessoais @closeModal="closeModal" v-if="!loaderModal" />
        </v-tab-item>
        <v-tab-item
          :value="'tab-2'">
          <premios @closeModal="closeModal" />
        </v-tab-item>
        <v-tab-item
          :value="'tab-3'">
          <dadosBancario @closeModal="closeModal" />
        </v-tab-item>
        <v-tab-item
          :value="'tab-4'">
          <extratoCredito
          @closeModal="closeModal" />
        </v-tab-item>
        <v-tab-item
          :value="'tab-5'">
          <titulosCapitalizacao @closeModal="closeModal" />
        </v-tab-item>
        <v-tab-item
          :value="'tab-6'">
          <extratoPremio @closeModal="closeModal" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import Events from '@/core/service/events'
import { mask } from 'vue-the-mask'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'ModalUsuarioWeb',
  components: {
    dadosPessoais: () => import('./formDadosPessoais'),
    premios: () => import('./formPremios'),
    dadosBancario: () => import('./formDadosBancario'),
    extratoCredito: () => import('./extratoCredito'),
    titulosCapitalizacao: () => import('./titulosCapitalizacao'),
    extratoPremio: () => import('./extratoPremio'),
    Modal: () => import('../modal')
  },
  mixins: [validationMixin],
  directives: {
    mask
  },
  data: () => ({
    activeModal: false,
    tab: 'tab-1',
    loaderModal: false,
    id: ''
  }),
  mounted () {
    Events.$on('cadastro::openModalCadastro', item => {
      if (this.id !== item.id) {
        this.id = item.id
        this.loaderModal = true
        this.getUserToId({ id: item.id })
          .finally(() => this.loaderModal = false)
        this.openModal()
      }

    })
  },
  computed: {
    ...mapGetters('usuariosWeb', {
      item: 'item',
      totalItens: 'totalItens',
    }),
    ...mapGetters('roles', ['permiteAcao']),
        
    variables: () => variables,
  },
  methods: {
    ...mapActions('usuariosWeb', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'editSaveItem', 'setLoadingTable', 'getDocuments', 'getExtratoCredito', 'getExtratoPremio', 'getUserToId']),
    openModal () {
      this.activeModal = true
    },
    openPremioModal () {
      let self = this
      self.setLoadingTable(true)
      self.getDocuments(this.item).then(() => {
        self.setLoadingTable(false)
      })
    },
    openExtratoCreditoModal () {
      let self = this
      self.setLoadingTable(true)
      this.getExtratoCredito(self.item).then (() => {
        self.setLoadingTable(false)
      })
    },
    openTitulosCapitalizacao () {
      let self = this
      self.setLoadingTable(true)
      this.getExtratoCredito(self.item).then (() => {
        self.setLoadingTable(false)
      })
    },
    openExtratoPremioModal () {
      let self = this
      self.setLoadingTable(true)
      this.getExtratoPremio(self.item).then (() => {
        self.setLoadingTable(false)
      })
    },
    closeModal () {
      this.clearItens()
      this.activeModal = false
      this.tab = 'tab-1'
      this.id = ''
    },
  }
}
</script>

<style lang="scss" src="./style.scss"></style>